import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2025/renewable-solar-solution/1.jpg';
import blogd2 from '../../assets/images/2025/renewable-solar-solution/2.jpg';
import blogd3 from '../../assets/images/2025/renewable-solar-solution/3.jpg';
import blogd4 from '../../assets/images/2025/renewable-solar-solution/4.jpg';
import blogd5 from '../../assets/images/2025/renewable-solar-solution/5.jpg';
import blogd6 from '../../assets/images/2025/renewable-solar-solution/6.jpg';
import blogd7 from '../../assets/images/2025/renewable-solar-solution/7.jpg';
import blogd8 from '../../assets/images/2025/renewable-solar-solution/8.jpg';
import blogd9 from '../../assets/images/2025/renewable-solar-solution/9.jpg';
import blogd10 from '../../assets/images/2025/renewable-solar-solution/10.jpg';
import blogd11 from '../../assets/images/2025/renewable-solar-solution/11.jpg';
// import blogd12 from '../../assets/images/2024/senate-committee-commends-mgt/12.jpeg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  Donation and Installation of 12.5kVA / 20kW Renewable Energy
                  Solar Solution at Lagos University Teaching Hospital (LUTH)
                </h2>
                <div className='blog-one__image'>
                  <img src={blogd2} alt='' />
                </div>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  On the 9th of April, 2025,{' '}
                  <strong>
                    Chief (Dr.) Moses Adekoyejo Majekodunmi Foundation (MAMF)
                  </strong>{' '}
                  successfully commissioned the donation and installation of a
                  12.5kVA / 20kW renewable energy solar power solution at the
                  Ward A2 of the
                  <strong>
                    Lagos University Teaching Hospital (LUTH).
                  </strong>{' '}
                  The installation, which aims to improve energy reliability
                  within the hospital, marks a significant step toward
                  sustainable healthcare delivery in Nigeria.
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd7} height={370} />
                  </div>
                  <div
                    className='col-lg-6'
                    style={{ textAlign: 'left', alignItems: 'right' }}
                  >
                    <img src={blogd3} height={370}/>
                    <p>
                      The Chief Medical Director of LUTH, Professor Wasiu Lanre
                      Adeyemo, officially welcomed the Foundation team during a
                      brief commissioning ceremony held within the hospital. In
                      his address, Professor Adeyemo spoke on the challenges
                      that power outages have posed to medical care, He
                      expressed his sincere gratitude to MAMF for the generous
                      intervention, noting that the new solar energy solution
                      would greatly enhance patient care and hospital
                      operations.
                      <br />
                      <br />
                      “We are constantly faced with the limitations of
                      inconsistent power supply,” the CMD said. “This donation
                      is timely and impactful. It brings real, lasting change to
                      how we function. Our goal is to eventually power all parts
                      of LUTH with sustainable energy, and this is a major step
                      in the right direction.”
                    </p>
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Nurse Ndidi Enuwa Retires */}
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Following the CMD’s remarks, Dr. Oladapo Majekodunmi, Vice
                  Chairman of the Foundation, reaffirmed MAMF’s ongoing
                  dedication to supporting healthcare infrastructure and
                  expressed optimism about doing even more in the future.
                  <br />
                  Also present at the event was Mr. Femi Numa, Director/CEO of
                  Taranis Novus Limited (TNL), the firm responsible for
                  executing the solar installation. Mr. Numa commended the LUTH
                  management for their collaboration and openness throughout the
                  process. “Working with LUTH was smooth and seamless,” he said.
                  “We’re proud to be part of this transformative project”
                  <div className='row'>
                    <div className='col-lg-6'>
                      <img src={blogd5} height={370} />
                    </div>
                    <div className='col-lg-6'>
                      <img src={blogd6} height={370} />
                    </div>
                  </div>
                  <br />
                  The event concluded with a tour of the installed solar system,
                  showcasing its setup and capabilities, as well as a photo
                  session with key stakeholders and hospital staff.
                  <br />
                  This project is a testament to the power of collaboration
                  between non-profit organizations, the private sector, and
                  public institutions in driving sustainable development and
                  improving lives.
                  <div className='row'>
                    <div className='col-lg-12'>
                      <img src={blogd4} height={370} />
                    </div>
                  </div>
                </p>

                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Baale of Mashewele community, community elder, HOD of
                  Community Health Dept Prof Ogunnowo, Dr Roberts ,Mushin LGA
                  Health Educator Mrs Akinterinwa and Residents in Community
                  Health.
                </span> */}
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Other pictures as found below:
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd8} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd9} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Fig 1: Residents at the department before the program */}
                  <br />
                  {/* Fig 2: Dr Roberts and Dr Balogun Consultants at Dept of Comm
                  Health at the Outreach. */}
                </span>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd10} height={370} width={500} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd11} height={370} />
                  </div>
                </div>

                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Residents dispensing medications in advance for the outreach */}
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd1} height={370} width={500} />
                  </div>
                </div>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'></a>
                </p>
              </div>
              <div className='social-block'>
                <a href='#none'>
                  <i className='fab fa-twitter'></i>
                </a>
                <a href='#none'>
                  <i className='fab fa-facebook-f'></i>
                </a>
                <a href='#none'>
                  <i className='fab fa-instagram'></i>
                </a>
                <a href='#none'>
                  <i className='fab fa-dribbble'></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='LAGOS UNIVERSITY TEACHING HOSPITAL | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
